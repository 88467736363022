import { computed } from "vue"
import { usePage } from "@inertiajs/vue3"
import { userLang } from "@/helpers/globals"

export function usePageProps() {
  const pageProps = computed(() => usePage()?.props)

  const cart = computed(() => pageProps.value?.cart)
  const cartCount = computed(() => pageProps.value?.cart?.total_quantity)

  const impersonate = computed(() => pageProps.value?.impersonate)
  const cookies = computed(() => pageProps.value?.cookies)
  const currentRoute = computed(() => pageProps.value?.routes?.currentRouteName)
  const locale = computed(() => pageProps.value?.locale)
  const supportedLocales = computed(() => pageProps.value?.supportedLocales)

  const user = computed(() => pageProps.value?.auth?.user)
  const isAdmin = computed(() => (user.value?.permissions || []).includes("admin-permission"))
  const hasApprovalManager = computed(() => !!user.value?.approval_manager_id)
  const financiallyApproved = computed(() => !!user.value?.financial_approval)

  const tenant = computed(() => pageProps.value?.tenant)
  const tenantId = computed(() => tenant.value?.id)
  const categoryBrowsingEnabled = computed(() => !!tenant.value?.category_browsing)
  const showOnlyFavoritedGifts = computed(() => !!tenant.value?.show_only_favorited_gifts)

  const customizationAdEnabled = computed(() => pageProps.value.tenant_subscription?.tier?.name !== 'Basic' && !!tenant.value?.customization_ad)
  const requiredToPayWhenOrdering = computed(() => !!tenant.value?.required_to_pay_when_ordering)

  const customizationAdCTALink = computed(
    () => pageProps.value?.customization_ad_cta_link?.[userLang],
  )

  const hasDirectFutureDeliveryNonVoucherGiftInCart = computed(
    () => pageProps.value?.cart?.has_direct_future_delivery_non_voucher_gift,
  )

  const themeEnabled = computed(() => pageProps.value?.theme?.enabled)
  const theme = computed(() => themeEnabled.value ? pageProps.value?.theme : undefined)
  const themeLogo = computed(() => themeEnabled.value ? pageProps.value?.theme?.logo : undefined)
  const logo = computed(() =>
    themeEnabled.value ? pageProps.value?.theme?.logo : pageProps.value?.logo || undefined,
  )

  return {
    cart,
    locale,
    supportedLocales,
    cookies,
    cartCount,
    impersonate,
    currentRoute,
    user,
    isAdmin,
    hasApprovalManager,
    financiallyApproved,
    tenantId,
    hasDirectFutureDeliveryNonVoucherGiftInCart,
    categoryBrowsingEnabled,
    showOnlyFavoritedGifts,
    requiredToPayWhenOrdering,
    customizationAdEnabled,
    customizationAdCTALink,
    theme,
    themeEnabled,
    themeLogo,
    logo,
  }
}
